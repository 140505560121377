import React, { useEffect, useState } from "react";
import { getItem, setItem } from "src/Services/Helper";
import { Http } from "src/Services/Http";
import { LayoutOne } from "lotuscremationlibrary";
import { useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { yellow } from "@mui/material/colors";

export default function QuestionBox({ color }) {
  let navigate = useNavigate();
  const [data, setData] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [token, setToken] = useState("");
  const [progress, setProgress] = useState(0);

  const checkToken = async () => {
    let token = await getItem("rand_token");
    if (token === null || token === "undefined") {
      await setItem("rand_token", Math.random().toString(36).substring(2));
    }
    return await getItem("rand_token");
  };

  const getQuestion = async (Token) => {
    await Http("POST", "guest/questionnaire", {
      type: 0,
      device_id: Token,
      is_last_attended: parseInt(await getItem("is_last")),
    }).then((response) => {
      if (response.status === 200) {
        setData(response.data);
        setProgress(
          ((response.data.progressbar.index - 1) /
            response.data.progressbar.total) *
            100
        );
      } else if (response.status === 400) {
        localStorage.setItem("stage", 1);
        navigate(`/register`);
      }
      // setResponse({ status, message });
    });
  };

  const postQuestion = async (answer, actions, is_next, is_skip = false) => {
    setLoading(true);
    await Http("POST", "guest/questionnaire", {
      type: 0,
      device_id: token,
      answer: answer.answer,
      current_question_id: data.question.question_id,
      is_next: is_next,
      is_string: [1, 4, 13, 14].includes(data.question.control),
      address:
        is_next && answer.address !== undefined && is_skip === false
          ? answer.address
          : "",
    }).then(async (response) => {
      if (response.status === 200) {
        await setData(response.data);
        setProgress(
          ((response.data.progressbar.index - 1) /
            response.data.progressbar.total) *
            100
        );
      } else if (response.status === 400) {
        localStorage.setItem("stage", 1);
        localStorage.setItem("is_last", 1);
        if (color !== undefined) {
          window.open("/register", "_blank");
        } else {
          navigate(`/register`);
        }
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    checkToken().then((token) => {
      setToken(token);
      getQuestion(token).then(() => {
        setLoading(false);
      });
    });
  }, []);
  const theme = createTheme({
    palette: {
      custom: {
        main: color,
        contrastText: "#f8f9fa",
      },
      error: { main: yellow[500] },
    },
    components: {
      MuiTextField: { styleOverrides: { root: { color: "red" } } },
    },
    typography: {
      button: {
        fontSize: "1rem",
        textTransform: "none",
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <LayoutOne
        data={data}
        loading={isLoading}
        onSubmitForm={postQuestion}
        progress={progress}
        isIframe={color === undefined ? false : true}
        color={color}
      />
    </ThemeProvider>
  );
}
