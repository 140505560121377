import React, { Component } from "react";
import { connect } from "react-redux";
import { Http } from "src/Services/Http";
import CenterLoader from "src/Components/Loader/CenterLoader";
import PackageBody from "./Components/PackageBody/index";
import * as Helper from "src/Services/Helper";
import {
  setCertificatePrice,
  setPackageData,
  emptyPackageData,
} from "src/redux/actions";
import { Grid } from "@material-ui/core";

export class PackageInfoView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      data: {},
      certificate: 1,
      minheight: "250px",
    };
  }

  setData = async (arg) => this.setState({ data: arg });
  setLoading = async (arg) => this.setState({ isLoading: arg });
  setCertificate = async (arg) => {
    this.setState({ certificate: arg });
    Helper.setItem("certificate", arg);
  };

  componentDidMount = async () => {
    const { setData, setLoading, setCertificate } = this;
    const { setCertificatePrice, setPackageData, emptyPackageData } =
      this.props;

    // const certificateCount = await Helper.getItem("certificate");
    emptyPackageData();
    // if (certificateCount === null) {
    //   setCertificate(1);
    // } else {
    //   setCertificate(certificateCount);
    // }
    const { data, status, message } = await Http("GET", "packages-services");
    if (status === 200) {
      Helper.setItem(
        "packageData",
        JSON.stringify({
          id: data.id,
          name: data.name,
          price: data.price,
          tax: data.tax,
        })
      );
      console.log(data,'package data');
      setData(data);
      setCertificatePrice(parseInt(data.certificate_rate));
      setCertificate(data.certificate_count);
      // certificateCount = data.certificate_count;

      data.addonCategory.map((item, index) => {
        item.addOns.map((additem, addindex) => {
          if (additem.is_selected) {
            setPackageData({
              id: additem.id,
              catId: item.id,
              name: additem.service_name,
              price: additem.price,
              tax: additem.tax,
              type: item.selection_type,
              quantity: additem.quantity ? additem.quantity : 0,
              selected: additem.quantity ? true : false,
              included: additem.is_included ? true : false,
              address: additem.address ? additem.address : "",
              state: additem.state ? additem.state : "",
            });
          }
        });
      });
      setLoading(false);
    } else {
      alert("API ERO+ROR" + message);
    }
  };

  render() {
    const { isLoading, data, certificate } = this.state;
    const { packageData } = this.props;
    const { QuestionConditionChk } = this.props;
    const { PreneedDoneChk } = this.props;

    const getTotalTax = () => {
      let packageTax = JSON.parse(localStorage.getItem("packageData")).tax;
      let addOnstax = parseFloat(packageTax);
      packageData.categoryData.map((item, index) => {
        addOnstax = addOnstax + item.tax * item.quantity;
      });

      let totaltax = addOnstax;
      return totaltax;
    };

    const getShippingTotal = () => {
      let shipping = 0;
      let shippingAddonData = packageData.categoryData.find(
        (item) => item.id === 10
      );
      packageData.categoryData.map((item, index) => {
        if (
          item.catId === 1 &&
          shippingAddonData !== undefined &&
          shippingAddonData.state === "California"
        ) {
          shipping = shipping + 30 * item.quantity;
        } else if (
          item.catId === 3 &&
          shippingAddonData !== undefined &&
          shippingAddonData.state === "California"
        ) {
          shipping = shipping + 30 * item.quantity;
        } else if (
          item.catId === 30 &&
          shippingAddonData !== undefined &&
          shippingAddonData.state === "California"
        ) {
          shipping = shipping + 10 * item.quantity;
        } else if (
          item.catId === 1 &&
          shippingAddonData !== undefined &&
          shippingAddonData.state !== undefined &&
          shippingAddonData.state !== "" &&
          shippingAddonData.state !== "California"
        ) {
          shipping = shipping + 50 * item.quantity;
        } else if (
          item.catId === 3 &&
          shippingAddonData !== undefined &&
          shippingAddonData.state !== undefined &&
          shippingAddonData.state !== "" &&
          shippingAddonData.state !== "California"
        ) {
          shipping = shipping + 50 * item.quantity;
        } else if (
          item.catId === 30 &&
          shippingAddonData !== undefined &&
          shippingAddonData.state !== undefined &&
          shippingAddonData.state !== "" &&
          shippingAddonData.state !== "California"
        ) {
          shipping = shipping + 10 * item.quantity;
        }
      });
      return shipping;
    };

    const getTotal = () => {
      let packagePrice = JSON.parse(localStorage.getItem("packageData")).price;
      //let packageTax = JSON.parse(localStorage.getItem("packageData")).tax;
      let addOnstotal = parseFloat(packagePrice);
      let addOnstax = getTotalTax();
      packageData.categoryData.map((item, index) => {
        if(item.included) {
          addOnstotal = addOnstotal + item.price * (item.quantity - 1) ;
        } else {
          addOnstotal = addOnstotal + item.price * item.quantity;
        }

        //addOnstax = addOnstax + item.tax * item.quantity;
      });

      let total =
        addOnstotal +
        addOnstax +
        (certificate * packageData.certificatePrice -
          packageData.certificatePrice) +
        getShippingTotal();
      return total;
    };
    if (isLoading) {
      return (
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundImage: `url("/img/Rectangle24.png")`,
            backgroundSize: "cover",
            height: "95vh",
            position: "relative",
            zIndex: "0",
          }}
          className="scrollable-section"
        >
          <CenterLoader minheight={this.state.minheight} />
        </Grid>
      );
    }
    return (
      <PackageBody
        data={data}
        total={getTotal()}
        certificate={certificate}
        setCertificate={this.setCertificate}
        crtPrice={packageData.certificatePrice}
        selected={packageData.categoryData}
        QuestionConditionChk={QuestionConditionChk}
        PreneedDoneChk={PreneedDoneChk}
        getTotalTax={getTotalTax()}
        getShippingTotal={getShippingTotal()}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  packageData: state.package,
  QuestionConditionChk: state.session.questconditionchk,
  PreneedDoneChk: state.session.preeneeddonechk,
});

const mapDispatchToProps = {
  setCertificatePrice,
  setPackageData,
  emptyPackageData,
};

export default connect(mapStateToProps, mapDispatchToProps)(PackageInfoView);
