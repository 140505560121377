import React, { useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
} from "@mui/material";

export default function ConfirmBox({ getConfirmation, getSection }) {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };


  return (
    <Grid container component={Paper} style={{ marginTop: 30 }}>
      <Grid container>
        <Grid
          item
          md={12}
          xs={12}
          style={{
            padding: 15,
            backgroundColor: "#b1d4e038",
          }}
        >
          Confirm
        </Grid>
        <Grid
          item
          md={12}
          style={{
            padding: 10,
          }}
        >
          <FormGroup>
            <FormControlLabel
              control={<Checkbox  checked={isChecked} onChange={handleCheckboxChange} />}
              label="I acknowledge that this questionnaire will be used to generate legal official documents. I have completed this questionnaire to the best of my ability and understand that these responses will be used to generate official legal documentation."
            />
          </FormGroup>
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
          style={{
            padding: 15,
            backgroundColor: "#b1d4e038",
          }}
        >
          <Grid container justifyContent={"space-between"}>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => getSection("Review")}
                className="btn-color"
              >
                Back
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={getConfirmation}
                className="btn-color"
                disabled={!isChecked}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
