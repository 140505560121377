import { TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import CenterLoader from "src/Components/Loader/CenterLoader";
//import * as Helper from "src/Services/Helper";
import { Http } from "src/Services/Http";

export default function ({ crtPrice, categoryData }) {
  const [Package, setPackage] = useState({});
  const [Category, setCategory] = useState([]);
  const [certificate, setcertificate] = useState(1);

  const [Discount, setDiscount] = useState(0);
  const [PromoCode, setPromocode] = useState("");
  const [isLoading, setisLoading] = useState(true);
  const [promoLoader, setpromoLoader] = useState(false);
  let total = Package.price + certificate * crtPrice - crtPrice;
  let totaltax = Package.tax;
  let serviceprice = 0;
  let shipping = 0;
  let shippingAddonData = categoryData.find((item) => item.id === 10);
  const [resStatus, setresStatus] = useState(null);
  const [resMessage, setresMessage] = useState("");

  useEffect(() => {
    setData();
    setisLoading(false);
  }, []);
  const setData = async () => {
    let package_data = await JSON.parse(localStorage.getItem("packageData"));
    setPackage(package_data);

    let Category_data = await JSON.parse(localStorage.getItem("categoryData"));
    setCategory(Category_data);

    await setcertificate(JSON.parse(localStorage.getItem("certificate")));
    await localStorage.setItem("discount", JSON.stringify({}));
  };
  const CheckCode = async () => {
    setpromoLoader(true);
    let category = [];
    categoryData.map(
      (
        item,
        index //(addOns[index] = item.id)
      ) => {
        return category.push({
          id: item.id,
          quantity: item.quantity ? item.quantity : 0,
          included: item.included ? item.included : 0,
          state: item.state !== undefined ? item.state : "",
          address: item.address !== undefined ? item.address : "",
        });
      }
    );
    let PackageId = await JSON.parse(localStorage.getItem("packageData")).id;
    if (Discount !== 0) {
      setDiscount(0);
      setresStatus({});
      setresMessage("");
      setPromocode("");
    } else {
      const response = await Http("POST", "discount", {
        promocode: PromoCode,
        package_id: PackageId,
        addons_ids: category,
        no_of_cert: certificate,
      });
      setresStatus(response.status);
      setresMessage(response.message);
      if (response.status === 200) {
        localStorage.setItem("discount", JSON.stringify(response.data));
        setDiscount(response.data.discount_amount);
      }
    }
    setpromoLoader(false);
    return true;
  };
  if (isLoading) {
    return <CenterLoader />;
  }
  return (
    <div className="col-md-6">
      <div className="order-section">
        <h3 className="text-center">Your Order</h3>
        <div className="order-total">
          <ul>
            <li>
              <p className="total-label">{Package.name} Package:</p>
              <p className="total-price">
                ${parseFloat(Package.price).toFixed(2)}
              </p>
            </li>
            {categoryData.map((item, index) => {
              if (item !== null) {
                if (
                  item.catId === 1 &&
                  shippingAddonData !== undefined &&
                  shippingAddonData.state === "California"
                ) {
                  shipping = shipping + 30 * item.quantity;
                } else if (
                  item.catId === 3 &&
                  shippingAddonData !== undefined &&
                  shippingAddonData.state === "California"
                ) {
                  shipping = shipping + 30 * item.quantity;
                } else if (
                  item.catId === 30 &&
                  shippingAddonData !== undefined &&
                  shippingAddonData.state === "California"
                ) {
                  shipping = shipping + 10 * item.quantity;
                } else if (
                  item.catId === 1 &&
                  shippingAddonData !== undefined &&
                  shippingAddonData.state !== undefined &&
                  shippingAddonData.state !== "" &&
                  shippingAddonData.state !== "California"
                ) {
                  shipping = shipping + 50 * item.quantity;
                } else if (
                  item.catId === 3 &&
                  shippingAddonData !== undefined &&
                  shippingAddonData.state !== undefined &&
                  shippingAddonData.state !== "" &&
                  shippingAddonData.state !== "California"
                ) {
                  shipping = shipping + 50 * item.quantity;
                } else if (
                  item.catId === 30 &&
                  shippingAddonData !== undefined &&
                  shippingAddonData.state !== undefined &&
                  shippingAddonData.state !== "California"
                ) {
                  shipping = shipping + 10 * item.quantity;
                }
                totaltax = totaltax + item.tax * item.quantity;
                serviceprice = item.included
                  ? item.price * (item.quantity - 1)
                  : item.price * item.quantity;
                total = total + serviceprice;
                if (index === categoryData.length - 1) {
                  total = total + shipping + totaltax;
                }

                return (
                  <li key={index}>
                    <p className="total-label">
                      {item.name}:{" "}
                      {item.type
                        ? "$" + item.price + " x " + item.quantity
                        : ""}
                      {item.included ? " (1 included)" : ""}
                    </p>
                    <p className="total-price">
                      {item.id === 10
                        ? "$" + parseFloat(shipping).toFixed(2)
                        : item.included
                        ? "$" +
                          parseFloat(item.price * (item.quantity - 1)).toFixed(
                            2
                          )
                        : "$" +
                          parseFloat(item.price * item.quantity).toFixed(2)}
                    </p>
                  </li>
                );
              }
            })}
            <li>
              <p className="total-label">Death certificates ({certificate}):</p>
              <p className="total-price">
                ${parseFloat(certificate * crtPrice - crtPrice).toFixed(2)}
              </p>
            </li>
            <li>
              <p className="total-label">Tax Calculation:</p>
              <p className="total-price">${parseFloat(totaltax).toFixed(2)}</p>
            </li>
            <li>
              <p className="total-label">Total:</p>

              <p className="total-price">${parseFloat(total).toFixed(2)}</p>
            </li>
            {Discount !== 0 && Discount < total && (
              <li>
                <p className="total-label">Discount:</p>
                <p className="total-price">
                  - ${parseFloat(Discount).toFixed(2)}
                </p>
              </li>
            )}
            {Discount !== 0 && Discount < total && (
              <li>
                <p className="total-label">Payable Amount:</p>
                <p className="total-price">
                  ${parseFloat(total - Discount).toFixed(2)}
                </p>
              </li>
            )}
          </ul>
        </div>
      </div>
      <div className="discount">
        <div className="row">
          <div className="col-lg-9 col-md-7">
            <TextField
              fullWidth
              label="Enter Discount Code"
              placeholder="Enter Discount Code"
              name="promocode"
              value={PromoCode}
              onChange={(e) => setPromocode(e.target.value)}
              readOnly={Discount !== 0 ? true : false}
              variant="outlined"
              autoComplete="off"
              style={{
                background: "rgba(94, 113, 106, 0.5)",
                border: "1px solid rgba(94, 113, 106, 0.5)",
                borderRadius: "5px",
                margin: "0px 0",
                padding: "0px 0px",
              }}
              onFocus={(e) => (e.target.placeholder = "")}
            />

            {/* <Form.Group controlId="validationCustom01">
              <Form.Control
                type="text"
                className="form-control custom-input"
                placeholder="Enter Discount Code"
                aria-label="discount Code"
                name="promocode"
                value={PromoCode}
                onChange={(e) => setPromocode(e.target.value)}
                readOnly={Discount !== 0 ? true : false}
                autoComplete="off"
              />
              
            </Form.Group> */}
          </div>
          <div className="col-md-3 col-md-3">
            <button
              type="text"
              className="btn-apply"
              onClick={() => CheckCode()}
              disabled={promoLoader}
            >
              {promoLoader ? (
                <div className="d-flex justify-content-center">
                  <span
                    className="spinner-border text-light"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </div>
              ) : Discount !== 0 ? (
                "Remove"
              ) : (
                "Check"
              )}
            </button>
          </div>
        </div>
        {resStatus === 200 && (
          <Form.Control.Feedback className="d-block">
            {resMessage}
          </Form.Control.Feedback>
        )}
        {resStatus !== 200 && resStatus !== null && (
          <Form.Control.Feedback type="invalid" className="d-block">
            {resMessage}
          </Form.Control.Feedback>
        )}
      </div>
    </div>
  );
}
