import React from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import * as Messages from "src/constants/Messages";
import CenterLoader from "src/Components/Loader/CenterLoader";
import TextInput from "src/Components/Formik/TextInput";
import { Grid, Button, Paper, InputLabel, FormHelperText } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { useLocation } from "react-router";

const validationSchema = Yup.object().shape({
    star: Yup.string().required(Messages.FIELD_REQUIRED),
    remark:Yup.string().required(Messages.FIELD_REQUIRED),
});
export default function ReviewFormDetails({ handleRegister,submitReview,submitError,showReviewForm,submitErrorMsg }) {
    const resetFeedbackToken = new URLSearchParams(useLocation().search).get("token");
    const initialValues = {
        token: resetFeedbackToken,
        star: "",
        remark: "",
    };
    return (
        <Paper elevation={3} style={{ padding: 25, minHeight: 280 }}>
            <Grid container direction="column">
                <Grid item>
                    <h6 className="que-title">Feedback</h6>
                    <hr />
                </Grid>
                {submitReview ? (
                    <Grid item>
                        <h6 className="que-title">Thank you for submitting your feedback</h6>
                    </Grid>
                ) : submitError ? (
                    <Grid item>
                        <h6 className="que-title">{submitErrorMsg}</h6>
                    </Grid>
                ) : showReviewForm ? (
                <Grid item style={{ padding: 15 }}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={async (
                            values,
                            { setSubmitting, resetForm, setErrors }
                        ) => {
                            await handleRegister(values, setErrors);
                            setSubmitting(true);
                        }}
                    >
                        {({
                            errors,
                            touched,
                            isSubmitting,
                            handleSubmit,
                            setFieldValue,
                            handleChange,
                            handleBlur,
                            isValid,
                        }) => {
                            if (isSubmitting) {
                                return <CenterLoader />;
                            }
                            return (
                                <Form onSubmit={handleSubmit}>
                                <Field type="hidden" name="token"  />
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={12}>
                                            <InputLabel id="star">
                                                Rating
                                            </InputLabel>
                                            <Rating
                                                id="star"
                                                name="star"
                                                defaultValue={0}
                                                precision={0.5}
                                                onChange={(e) => {
                                                    setFieldValue(
                                                        "star",
                                                        e.target.value
                                                    );
                                                }}
                                                className="rating"
                                            />
                                            {Boolean(touched.star && errors.star) ? (
                                                <FormHelperText className="Mui-error">
                                                {errors.star}
                                                </FormHelperText>
                                            ) : null}
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <TextInput
                                                name="remark"
                                                label="Review"
                                                variant="outlined"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                margin="normal"
                                                fullWidth
                                                multiline
                                                rows={3}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Grid item style={{ paddingTop: 10 }}>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                type="submit"
                                                disabled={!isValid}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                </Grid>
                ): null  }
            </Grid>
        </Paper>
    );
}
