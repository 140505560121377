import React from "react";

export default function Footer() {
  let newDate = new Date();
  let year = newDate.getFullYear();
  return (
    <footer>
      <div className="cm-footer">
        <div className="cm-footer-copy">
          <p>Copyright © {year} Fernwood Cemetery, All rights reserved.</p>
        </div>
        <div className="cm-footer-menu">
          <ul>
            <li>
              <a href="https://www.fernwood.com/terms-of-service">Terms and conditions</a>
            </li>
            <li>
              <a href="https://www.fernwood.com/privacy-policy">Privacy policy </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
