import React, { useState, useEffect } from "react";

import {
  LayoutTwo,
  Review,
  SectionBox,
  ConfirmBox,
  SecureAreaBox,
} from "lotuscremationlibrary";
import Page from "src/Components/Page";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Http } from "src/Services/Http";
import { FormHelperText, Grid } from "@mui/material";
import {
  setPreNeedDoneChk,
  setPaymentExtraMailsChk,
  setPostQaCheck,
  checkExtraMails,
} from "src/redux/actions/sessionActions";
// import { useDispatch } from "react-redux";
// import * as actionTypes from "src/redux/actions";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export function PostQuest(props) {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [reviewData, setReviewData] = useState({});
  const [reviewerror, setReviewError] = useState("");
  const [confirmationData, setConfirmationData] = useState({});
  const [confirmLoadingMsg, setConfirmLoadingMsg] = useState("");
  const [reviewIndex, setReviewIndex] = useState(null);
  const [progress, setProgress] = useState(0);
  const [pageType, setPageType] = useState("quest"); //it can be quest,review,conform,conformation
  const location = useLocation();
  const paths = location.pathname.split("/");
  const urlName = paths[2];
  const getUrlName = urlName === "gwquest" ? "Great Western" : "Post Question";
  let isPostQueStart = localStorage.getItem("isPostQueStart");
  let isGwQueStart = localStorage.getItem("isGwQueStart");

  const setResponse = async (res, type) => {
    if (res.status === 200) {
      if (type === "confirm") {
        if (data.sections[data.sections.length - 2] !== undefined) {
          let newData = data;
          newData.sections[data.sections.length - 2].is_active = false;
          newData.sections[data.sections.length - 2].is_complete = true;
          setData(newData);
        }
        await setConfirmationData(res.data);
      } else if (type === "review") {
        await setReviewData(res.data);
      } else {
        await setProgress(
          ((res.data.progressbar.index - 1) / res.data.progressbar.total) * 100
        );
        await setData(res.data);
        if (
          res.data.is_review !== undefined &&
          ((res.data.sections[res.data.sections.length - 3].is_complete &&
            res.data.is_review === 1) ||
            (res.data.is_review === 0 && res.data.is_confirm === 1) ||
            (res.data.is_review === 0 && res.data.is_confirm === 0))
        ) {
          await getSectionQuest("Review");
        }
      }
    } else if (res.status === 400) {
      await setPageType("review");
      await setReviewError(res.message);
      //await getSectionQuest("Review");
    } else {
      alert("something went wrong");
    }
  };
  const makeCall = async (method, url, payload, type) => {
    setLoading(true);
    let globResponse = {};
    if (method === "POST") {
      await Http(method, url, payload).then((response) => {
        globResponse = response;
      });
    } else {
      await Http(method, url).then((response) => {
        globResponse = response;
      });
    }
    await setResponse(globResponse, type);
    setLoading(false);
  };
  const getQuestions = async () => {
    await setPageType("quest");
    const payload = {
      type: urlName === "gwquest" ? 2 : 1,
    };
    await makeCall("POST", "user/questionnaire", payload);
  };
  const postQuestions = async (values, actions, is_next, is_skip = false) => {
    setPageType("quest");
    let payload = {
      type: urlName === "gwquest" ? 2 : 1,
      is_next: is_next,
      answer:
        is_next && is_skip === false ? values.answer : data.question.answer,
      current_question_id: data.question.question_id,
      is_last: data.question.is_last,
      is_string: [1, 5, 6, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].includes(
        data.question.control
      )
        ? 1
        : data.question.control === 4 &&
          [1, 5, 6].includes(data.question.control_handler)
        ? 1
        : 0,
      address:
        is_next && values.address !== undefined && is_skip === false
          ? values.address
          : "",
      isFileSelected:
        is_next && is_skip === false && values.files !== undefined
          ? true
          : false,
    };
    await makeCall("POST", "user/questionnaire", payload);
    // console.log(data, "question data");
    // if (
    //   (data.question.is_last === 1 || data.question.is_last === true) &&
    //   (is_next === true || is_next === 1)
    // ) {
    //   await getSectionQuest("Review");
    // }
  };
  const getSectionQuest = async (section) => {
    const questType = urlName === "gwquest" ? 2 : 1;
    if (section === "Review") {
      await setPageType("review");
      await setReviewError("");
      await makeCall("GET", "user/review/" + questType, {}, "review");
    } else if (section === "Confirm") {
      await setPageType("confirm");
      const payload = {
        type: questType,
        is_review: true,
      };
      await makeCall("POST", "user/update_review", payload, "confirm");
    } else {
      await setPageType("quest");
      const payload = {
        type: questType,
        section_id: section,
      };
      await makeCall("POST", "user/questions_by_section", payload);
    }
  };
  const getConfirmation = async () => {
    setLoading(true);
    setConfirmLoadingMsg(
      "Thank you for completing your application with Fernwood Cemetery Cremation. Your application has now been submitted and will soon be sent to you via email for your signature."
    );
    const reviewPDFurl =
      urlName === "gwquest" ? "user/gen_gw_review_pdf" : "user/gen_review_pdf";
    const payload = {
      type: urlName === "gwquest" ? 2 : 1,
    };

    const confirmresponse = await Http("POST", reviewPDFurl, payload);

    if (confirmresponse.status === 200) {
      if (urlName === "gwquest") {
        props.setPreNeedDoneChk(true);
        // dispatch({ type: actionTypes.SESSION_SET_PRENEEDDONE, payload: true });
        // navigate("/app/payment");
        setTimeout(() => {
          setLoading(false);
          navigate("/app/payment");
        }, 5000);
      } else {
        props.setPaymentExtraMailsChk(!confirmresponse.data[0].is_extra_miles);
        props.checkExtraMails(confirmresponse.data[0].is_extra_miles);
        props.setPostQaCheck(true);

        // dispatch({
        //   type: actionTypes.SESSION_SET_EXTRAMAILES_PAYMENTCHK,
        //   payload: !confirmresponse.data[0].is_extra_miles,
        // });
        // dispatch({
        //   type: actionTypes.SESSION_SET_EXTRA_MAILS_CHECK,
        //   payload: confirmresponse.data[0].is_extra_miles,
        // });
        // dispatch({
        //   type: actionTypes.SESSION_SET_POST_QA_CHECK,
        //   payload: true,
        // });
        setPageType("confirmation");
        setConfirmLoadingMsg("");

        let newData = data;
        newData.sections[data.sections.length - 2].is_active = false;
        newData.sections[data.sections.length - 1].is_complete = true;
        setData(newData);
        setLoading(false);
        if (confirmresponse.data[0].is_extra_miles) {
          navigate("/app/extramailespayment");
        }
      }
    }

    //await makeCall("POST", reviewPDFurl, payload);
  };
  const setSecureArea = () => {
    urlName === "gwquest"
      ? localStorage.setItem("isGwQueStart", true)
      : localStorage.setItem("isPostQueStart", true);
    getQuestions();
  };

  useEffect(() => {
    getQuestions().then(async (response) => {
      await setLoading(false);
    });
  }, []);
  return (
    <Page title={getUrlName}>
      <div className="postque">
        <Grid container direction={"row"} className="main-body">
          <Grid item md={4} xs={12} className="scroll-md list-que">
            {data.sections && (
              <SectionBox
                section={data.sections}
                getSection={getSectionQuest}
                progress={progress}
                pageType={pageType}
                isFromAdmin={false}
              />
            )}
          </Grid>
          {(isGwQueStart === null && urlName === "gwquest") ||
          (isPostQueStart === null && urlName === "postquestv1") ? (
            <Grid item md={8} xs={12} className="scroll-md theme-bg-color">
              <SecureAreaBox setSecureArea={setSecureArea} />
            </Grid>
          ) : (
            <Grid item md={8} xs={12} className="scroll-md theme-bg-color">
              {isLoading && (
                <Grid container justifyContent={"center"}>
                  <img
                    src="/img/loader.gif"
                    className="spinner"
                    alt="Loading"
                  />
                </Grid>
              )}
              {isLoading && confirmLoadingMsg !== "" && (
                <Grid container justifyContent={"center"}>
                  <FormHelperText style={{ color: "#ffeb3b" }}>
                    {confirmLoadingMsg}
                  </FormHelperText>
                </Grid>
              )}
              {pageType === "quest" && !isLoading && (
                <LayoutTwo
                  loading={isLoading}
                  data={data}
                  onSubmitForm={postQuestions}
                />
              )}
              {pageType === "review" && !isLoading && (
                <Review
                  reviewSection={data.sections[data.sections.length - 2]}
                  prevSection={
                    data.sections[data.sections.length - 3].section_id
                  }
                  type={urlName === "gwquest" ? 2 : 1}
                  confirmed={
                    data.is_confirm === 0
                      ? // data.sections[data.sections.length - 1].is_complete === 1
                        true
                      : false
                  }
                  reviewData={reviewData}
                  reviewerror={reviewerror}
                  onSectionGet={getSectionQuest}
                  isFromAdmin={false}
                />
              )}
              {pageType === "confirm" && !isLoading && (
                <ConfirmBox
                  getConfirmation={getConfirmation}
                  getSection={getSectionQuest}
                />
              )}
              {pageType === "confirmation" && !isLoading && (
                <Grid
                  container
                  style={{
                    marginTop: 30,
                    textAlign: "Center",
                  }}
                >
                  <Grid item md={12} xs={12}>
                    <CheckCircleOutlineIcon
                      sx={{ fontSize: 100, color: "red" }}
                    />
                  </Grid>
                  <Grid item md={12} xs={12} style={{ color: "yellow" }}>
                    Your application has been submitted successfully.
                    <br />
                    <br />
                    We have sent your completed application to you via email for
                    signing. Please check your email and electronically sign to
                    finalize your documents. If you do not see them within the
                    next few minutes, please check your spam folder before
                    emailing{" "}
                    <a
                      href="mailto:cremation-support@fernwoodcemetery.com"
                      style={{ color: "#D8B34F" }}
                    >
                      cremation-support@fernwoodcemetery.com
                    </a>
                    <br />
                    <br />
                    We sincerely appreciate you allowing us to be of service.
                    <br />
                    <br />
                    Fernwood Cemetery.
                    {/* Your application is submitted successfully. You got mail
                    containing application pdf for review.
                    <br /> Please check your inbox. */}
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </div>
    </Page>
  );
}

const mapStateToProps = (state, ownProps) => ({
  currentId: parseInt(ownProps.id, 10),
  pageNumber: parseInt(ownProps.id, 10),
});

const mapDispatchToProps = {
  setPreNeedDoneChk,
  setPaymentExtraMailsChk,
  setPostQaCheck,
  checkExtraMails,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostQuest);
