import { Typography } from "@material-ui/core";
import { Button, Link } from "@mui/material";
import React from "react";
import { createUseStyles } from "react-jss";
import { connect } from "react-redux";
import Page from "src/Components/Page";

const useStyles = createUseStyles({
    infoText : {
      fontSize: "18px !important",
      fontWeight: "bold !important",
      color:"#18464A",
    },
    linkStyle : {
      color:"#D8B34F !important",
      textAlign:"center",
      display:"block",
      textDecoration:"none !important",
    },
    redirectdiv : {
      textAlign:"center",
    }
});

export const PageNotFound = (props) => {
  const classes = useStyles();
  return (
    <div>
      <Page title="Page Not Found">
        <div className="">
        <div className="row">
            <div className="col-md-3 my-auto"></div>
            <div className="col-md-6">
              <div className="que-bg">
                <Typography className={classes.infoText} align="center">404</Typography>
                <div className="que-option">
                  <Typography className={classes.infoText} align="center" paragraph={true}>Sorry. We were unable to locate the page you were looking for.</Typography>
                  <Typography className={classes.infoText} align="center" paragraph={true}>Please click the link below to return to the home screen. If you encountered this page unexpectedly, please email us at:</Typography>
                  <Link
                    className={classes.linkStyle}
                    href="mailto:cremation-support@fernwoodcemetery.com"
                    align="center"
                  >
                    cremation-support@fernwoodcemetery.com
                  </Link>
                  <div className={classes.redirectdiv}>
                    <Button
                      className="homenavbutton"
                      component={Link}
                      variant="contained"
                      align="center"
                      href="/"
                    >
                      Home
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 my-auto"></div>
          </div>
          {/* <Row className="justify-content-md-center">
            <Col md={7}>
              
              {/* <Image src="img/undraw/not_found.png" fluid /> }
            </Col>
          </Row> */}
        </div>
      </Page>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PageNotFound);
