import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import CenterLoader from "src/Components/Loader/CenterLoader";
import { Http } from "src/Services/Http";
import { connect } from "react-redux";
import {
  setVerify,
  setPaymentChk,
  setQuestConditionChk,
  setPreNeedDoneChk,
  setPaymentExtraMailsChk,
  setPaymentExtraMailsChkTem,
  setPackageSelected,
  setPostQaCheck,
  checkExtraMails,
} from "src/redux/actions";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Grid } from "@material-ui/core";
// import { useLocation } from "react-router";

export const CheckVerifiedView = ({
  setVerify,
  setPaymentChk,
  setQuestConditionChk,
  setPreNeedDoneChk,
  ispackageSelected,
  setPaymentExtraMailsChk,
  setPaymentExtraMailsChkTem,
  setPackageSelected,
  setPostQaCheck,
  checkExtraMails,
}) => {
  //const varifyEmailToken  = new URLSearchParams(useLocation().search).get("token");
  const [isLoading, setisLoading] = useState(true);
  const [error, setError] = useState("");
  const [minheight] = useState("250px");
  const navigate = useNavigate();
  const sendVerify = async () => {
    setisLoading(true);
    const sendverifyresponse = await Http("POST", "guest/send_verify_link");
    if (sendverifyresponse !== null && sendverifyresponse.status === 200) {
      setisLoading(false);
      setError(sendverifyresponse.message);
    } else {
      setisLoading(false);
      console.log(sendverifyresponse);
    }
  };
  useEffect(() => {
    setVerify(false);
    setPaymentChk(false);
    setQuestConditionChk(false);
    const getData = async () => {
      await Http("GET", "user_verified_payment").then(async (response) => {
        // console.log(response.data);
        setVerify(response.data.isVerified);
        setPaymentChk(response.data.isPayment);
        setQuestConditionChk(response.data.isPreneed);
        setPackageSelected(
          response.data.isPackageSelected !== undefined
            ? response.data.isPackageSelected
            : false
        );
        setPreNeedDoneChk(
          response.data.isPreneedDone !== undefined
            ? response.data.isPreneedDone
            : false
        );
        setPaymentExtraMailsChk(
          response.data.isExtraMilesPayment !== undefined
            ? response.data.isExtraMilesPayment
            : false
        );
        setPaymentExtraMailsChkTem(
          response.data.isExtraMailsPaymentDone !== undefined
            ? response.data.isExtraMailsPaymentDone
            : false
        );
        setPostQaCheck(
          response.data.isPostQuesConfirm !== undefined
            ? response.data.isPostQuesConfirm
            : false
        );
        checkExtraMails(
          response.data.isPostConfirmExtraDone !== undefined
            ? response.data.isPostConfirmExtraDone
            : false
        );

        /*if (response.data.isVerified && (!response.data.isPayment && !response.data.isExtraMilesPayment)) {
          navigate("/app/packages");
        } else if (response.data.isVerified && (response.data.isPayment || response.data.isExtraMilesPayment)) {
          navigate("/app/postquest");
        } else if(response.data.isVerified && response.data.isPayment && !response.data.isExtraMilesPayment){
          navigate("/app/extramailespayment");
        }*/

        if (response.data.isVerified) {
          navigate("/app/home");
        }

        setisLoading(false);
      });
    };
    getData();
  }, [
    setVerify,
    setPaymentChk,
    setQuestConditionChk,
    setPreNeedDoneChk,
    setPaymentExtraMailsChk,
    setPaymentExtraMailsChkTem,
    navigate,
  ]);
  if (isLoading) {
    return (
      <Grid
        // style={{
        //   display: "flex",
        //   justifyContent: "center",
        //   minHeight: 500,
        // }}
        container
        direction="row"
        style={{
          backgroundImage: `url("/img/Rectangle24.png")`,
          backgroundSize: "cover",
          height: "95vh",
          justifyContent: "center",
          position: "relative",
          zIndex: "0",
        }}
        className="homesection_main"
      >
        <CenterLoader minheight={minheight} />
      </Grid>
    );
  }
  return (
    <Grid
      container
      direction="row"
      style={{
        backgroundImage: `url("/img/Rectangle24.png")`,
        backgroundSize: "cover",
        height: "95vh",
        position: "relative",
        zIndex: "0",
      }}
      className="homesection_main"
    >
      <Container>
        <Row>
          <Col>
            <div className="thanks-section">
              <h2>Thank You! For choosing Fernwood Cemetery</h2>
              <div className="thank-box">
                <p className="">
                  {/* Your email address has not yet been verified. If you have not
                received your verification email, please use the resend button
                below. */}
                  You have been sent an email for verification purposes. Please
                  check your email and click the link contained within. If you
                  have not received an email within a few minutes, please click
                  the link below to resend.
                </p>
                <Button
                  className="btn-resend"
                  onClick={() => {
                    sendVerify();
                  }}
                >
                  Resend Verification
                </Button>
                {error !== "" ? <p>{error}</p> : null}
              </div>
              <div className="col-md-12 gap-3 text-center"></div>
            </div>
          </Col>
        </Row>
      </Container>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  ispackageSelected: state.package.ispackageSelected,
});

const mapDispatchToProps = {
  setVerify,
  setPaymentChk,
  setQuestConditionChk,
  setPreNeedDoneChk,
  setPaymentExtraMailsChk,
  setPaymentExtraMailsChkTem,
  setPackageSelected,
  setPostQaCheck,
  checkExtraMails,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckVerifiedView);
