import React, { useState } from "react";
import { connect } from "react-redux";
import RadioOption from "../RadioOption";
import { FormHelperText, TextField } from "@mui/material"; //"@material-ui/core";
import Autocomplete from "react-google-autocomplete";
import { setPackageData } from "src/redux/actions";
export const CategorySelection = ({ data, selected, ...otherProps }) => {
  const { id, name, description, addOns, selection_type } = data;
  const price = selected.reduce(
    (a, { price, quantity, included }) =>
      included ? a + price * (quantity - 1) : a + price * quantity,
    0
  );
  let deliveryShippingAddress = otherProps.categoryData.find(
    (item) => item.id === 10
  );
  const [collectPerson, setcollectPerson] = useState(
    "Fernwood Cemetery At 6000 Santa Monica Blvd Los Angeles, CA, 90038"
  );
  return (
    <div>
      <div className="payment-op1">
        <h3>{`${name}`}</h3>
        {selection_type ? (
          <h6 className="text-white">${parseFloat(price).toFixed(2)}</h6>
        ) : (
          <h6 className="text-white">
            {deliveryShippingAddress && deliveryShippingAddress.catId === id
              ? "$" + parseFloat(otherProps.getShippingTotal).toFixed(2)
              : ""}
          </h6>
        )}
      </div>
      {description !== "" && description !== null ? (
        <div className="cat-description">
          <h3>{description}</h3>
        </div>
      ) : (
        ""
      )}

      <div className="que-option que-option-tow">
        {addOns.map((item, index) => {
          return (
            <>
              <RadioOption
                key={index}
                data={item}
                categoryKey={id}
                selected={selected.find(({ id }) => item.id === id)}
                type={selection_type}
                collectPerson={collectPerson}
                setErrorMessageForUrn={otherProps.setErrorMessageForUrn}
                setErrorMessageForAdditionServices={
                  otherProps.setErrorMessageForAdditionServices
                }
                setErrorMessageForDelivery={
                  otherProps.setErrorMessageForDelivery
                }
                setErrorMessageForDeliveryAddress={
                  otherProps.setErrorMessageForDeliveryAddress
                }
              />
            </>
          );
        })}
      </div>
      {selection_type === 0 &&
      id === 2 &&
      otherProps.categoryData.find(
        (item) => item.id === 9 || item.id === 10
      ) ? (
        <div className="delivery_address">
          {deliveryShippingAddress ? (
            <>
              <TextField
                sx={{ bgcolor: "#fff", borderColor: "#000", borderRadius: 1 }}
                fullWidth
                name=""
                id="outlined-basic"
                label=""
                hiddenLabel={true}
                variant="standard"
                InputProps={{
                  inputComponent: ({
                    inputRef,
                    onFocus,
                    onBlur,
                    onChange,
                    ...props
                  }) => (
                    <Autocomplete
                      sx={{
                        bgcolor: "#fff",
                        borderColor: "#000",
                        borderRadius: 1,
                      }}
                      {...props}
                      apiKey={"AIzaSyAW9PMZKxsJ3iAZ249oFM6k8M1xYKhMXhw"}
                      options={{
                        types: ["geocode", "establishment"],
                        fields: ["ALL"],
                      }}
                      onPlaceSelected={(place) => {
                        place.address_components.length > 0 &&
                          place.address_components.map((address_component) => {
                            if (
                              address_component.types[0] ===
                              "administrative_area_level_1"
                            ) {
                              deliveryShippingAddress = {
                                ...deliveryShippingAddress,
                                state: address_component.long_name,
                              };
                              otherProps.setPackageData(
                                deliveryShippingAddress
                              );
                              otherProps.setErrorMessageForDeliveryAddress("");
                            }
                          });
                        deliveryShippingAddress = {
                          ...deliveryShippingAddress,
                          address: place.formatted_address,
                        };
                        otherProps.setPackageData(deliveryShippingAddress);
                        otherProps.setErrorMessageForDeliveryAddress("");
                      }}
                      // options={{
                      //   types: ["establishment"],
                      // }}
                      defaultValue={
                        deliveryShippingAddress.address !== undefined &&
                        deliveryShippingAddress.address !== ""
                          ? deliveryShippingAddress.address
                          : ""
                      }
                      onChange={(e) => {
                        if (e.target.value === "") {
                          deliveryShippingAddress = {
                            ...deliveryShippingAddress,
                            address: e.target.value,
                          };
                          otherProps.setPackageData(deliveryShippingAddress);
                          otherProps.setErrorMessageForDeliveryAddress(
                            "Please enter delivery address."
                          );
                        }
                      }}
                    />
                  ),
                }}
                onChange={(e) => {
                  otherProps.setDeliveryAddress(e.target.value);
                }}
                error={otherProps.errorMessageForDeliveryAddress !== ""}
                margin="normal"
                required
              />
              {/* <FormHelperText error margin="normal">
                {otherProps.errorMessageForDeliveryAddress}
              </FormHelperText> */}
            </>
          ) : (
            <TextField
              sx={{ bgcolor: "#fff", borderColor: "#000", borderRadius: 1 }}
              fullWidth
              hiddenLabel
              name="collect_person_address"
              id="outlined-basic"
              // label="Delivery Address"
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              value={collectPerson}
              margin="normal"
            />
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  categoryData: state.package.categoryData,
});

const mapDispatchToProps = { setPackageData };

export default connect(mapStateToProps, mapDispatchToProps)(CategorySelection);
