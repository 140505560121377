import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReviewFormDetails from "./Components/ReviewFormDetails";
import { Http } from "src/Services/Http";
import Page from "src/Components/Page";
import { login } from "src/redux/actions/sessionActions";
import { useLocation } from "react-router";
export const Feedback = () => {
  const [submitReview, setSubmitReview] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [submitErrorMsg, setSubmitErrorMsg] = useState(false);
  const [showReviewForm, setShowReviewForm] = useState(false);
  const resetFeedbackToken = new URLSearchParams(useLocation().search).get(
    "token"
  );
  const handleRegister = async (values, setErrors) => {
    await Http("POST", "add_review", {
      ...values,
    }).then(async (response) => {
      const { status, data } = response;
      if (status === 200) {
        setSubmitReview(true);
      } else {
        setSubmitError(true);
        setSubmitErrorMsg("You have already submitted your feedback");
        if (status === 422) {
          setErrors(data);
        }
      }
    });
  };
  useEffect(() => {
    const checkActivity = async () => {
      let chekvalue = { token: resetFeedbackToken };
      await Http("POST", "check_review", chekvalue).then(async (response) => {
        const { status, data } = response;
        if (status === 400) {
          setShowReviewForm(false);
          setSubmitError(true);
          setSubmitErrorMsg(data["flag_msg"]);
        } else {
          console.log(status);
          if (status === 422) {
            setShowReviewForm(false);
            setSubmitError(true);

            setSubmitErrorMsg(data.token);
          } else {
            setShowReviewForm(true);
          }
        }
      });
    };
    checkActivity();
  }, [resetFeedbackToken]);
  return (
    <Page title="Fernwood Cemetery Feedback">
      <div className="que-section">
        <div className="row">
          <div className="col-md-2 my-auto"></div>
          <div className="col-md-8 my-auto mx-auto">
            <ReviewFormDetails
              handleRegister={handleRegister}
              submitReview={submitReview}
              submitError={submitError}
              submitErrorMsg={submitErrorMsg}
              showReviewForm={showReviewForm}
            />
          </div>
          <div className="col-md-2 my-auto"></div>
        </div>
      </div>
    </Page>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  login,
};

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
