import React from "react";
import { Formik } from "formik";
import { usePaymentInputs } from "react-payment-inputs";
import { Col, Form, Row } from "react-bootstrap";
import images from "react-payment-inputs/images";
import * as Yup from "yup";
import * as Messages from "src/constants/Messages";
import { RadioGroup } from "@mui/material";
import { Radio } from "@mui/material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
//import { HelmetProvider } from "react-helmet-async";

export default function PaymentForm({ setPaymentInfo, setValid }) {
  const {
    meta,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();
  const validationSchema = Yup.object().shape({
    holderName: Yup.string().required(Messages.FIELD_REQUIRED),
  });
  const { erroredInputs, touchedInputs } = meta;
  return (
    <div className="col-md-6">
      <div className="payment-method">
        <Formik
          initialValues={{
            holderName: "",
            cardNumber: "",
            expiryDate: "",
            cvc: "",
            zip: "",
          }}
          onSubmit={(data) => setPaymentInfo(data)}
          validationSchema={validationSchema}
          validate={() => {
            let errors = {};
            if (meta.erroredInputs.cardNumber) {
              errors.cardNumber = meta.erroredInputs.cardNumber;
            }
            if (meta.erroredInputs.expiryDate) {
              errors.expiryDate = meta.erroredInputs.expiryDate;
            }
            if (meta.erroredInputs.cvc) {
              errors.cvc = meta.erroredInputs.cvc;
            }
            return errors;
          }}
        >
          {({
            values,
            errors,
            touched,
            isValid,
            handleBlur,
            handleChange,
            handleSubmit,
            validateForm,
            setErrors,
          }) => {
            return (
              <form
                onSubmit={handleSubmit}
                onChange={async () => {
                  localStorage.setItem("PaymentData", JSON.stringify(values));
                  await validateForm().then(() => setErrors(errors));
                  if (!meta.error && values.holderName !== "") {
                    setValid(isValid);
                  } else {
                    setValid(false);
                  }
                }}
                onBlur={async () => {
                  localStorage.setItem("PaymentData", JSON.stringify(values));
                  await validateForm().then(() => setErrors(errors));
                  if (!meta.error && values.holderName !== "") {
                    setValid(isValid);
                  } else {
                    setValid(false);
                  }
                }}
              >
                <div className="row">
                  <div className="col-md-12">
                    <h3>Payment Method</h3>
                  </div>
                </div>

                <Grid item md={12} xs={12}>
                  <div className="payment-icon">
                    <RadioGroup
                      aria-label="gender"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      value={"ture"}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="ture"
                        control={<Radio />}
                        label="Credit Card"
                      />
                    </RadioGroup>
                    <svg {...getCardImageProps({ images })} />
                    {/* <img src="img/payment1.png" class="pcard" /> */}
                  </div>
                </Grid>
                <Row>
                  <Col md={12} className="p-0 mt-2">
                    <div className="line-pay"></div>
                  </Col>
                </Row>
                <Grid container spacing={3}>
                  <Grid item md={12}>
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <TextField
                          fullWidth
                          label="Card holder Name"
                          placeholder="Card holder Name"
                          name="holderName"
                          value={values.holderName}
                          type="text"
                          margin="normal"
                          variant="outlined"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          autoComplete="off"
                          error={Boolean(
                            touched.holderName && errors.holderName
                          )}
                          helperText={touched.holderName && errors.holderName}
                          onFocus={(e) => (e.target.placeholder = "")}
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <TextField
                          inputProps={getCardNumberProps({
                            onBlur: handleBlur,
                            onChange: handleChange,
                          })}
                          isinvalid={
                            (touchedInputs.cardNumber &&
                              erroredInputs.cardNumber) ||
                            (touched.cardNumber && errors.cardNumber)
                          }
                          fullWidth
                          label="Card Number"
                          name="cardNumber"
                          value={values.cardNumber}
                          type="text"
                          margin="normal"
                          variant="outlined"
                          autoComplete="off"
                          error={Boolean(
                            (touchedInputs.cardNumber &&
                              erroredInputs.cardNumber) ||
                              (touched.cardNumber && errors.cardNumber)
                          )}
                          helperText={
                            Boolean(
                              (touchedInputs.cardNumber &&
                                erroredInputs.cardNumber) ||
                                (touched.cardNumber && errors.cardNumber)
                            )
                              ? erroredInputs.cardNumber
                              : ""
                          }
                          onFocus={(e) => (e.target.placeholder = "")}
                        />
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <TextField
                          inputProps={getExpiryDateProps({
                            onBlur: handleBlur,
                            onChange: handleChange,
                          })}
                          fullWidth
                          value={values.expiryDate}
                          name="expiryDate"
                          label="Expiry Date"
                          type="text"
                          margin="normal"
                          variant="outlined"
                          autoComplete="off"
                          error={Boolean(
                            (touchedInputs.expiryDate &&
                              erroredInputs.expiryDate) ||
                              (touched.expiryDate && errors.expiryDate)
                          )}
                          helperText={
                            Boolean(
                              (touchedInputs.expiryDate &&
                                erroredInputs.expiryDate) ||
                                (touched.expiryDate && errors.expiryDate)
                            )
                              ? erroredInputs.expiryDate
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <TextField
                          fullWidth
                          inputProps={getCVCProps({
                            onBlur: handleBlur,
                            onChange: handleChange,
                          })}
                          label="CVV Code"
                          placeholder="CVV Code"
                          value={values.cvc}
                          name="cvc"
                          type="text"
                          margin="normal"
                          variant="outlined"
                          autoComplete="off"
                          error={Boolean(
                            (touchedInputs.cvc && erroredInputs.cvc) ||
                              (touched.cvc && errors.cvc)
                          )}
                          helperText={
                            Boolean(
                              (touchedInputs.cvc && erroredInputs.cvc) ||
                                (touched.cvc && errors.cvc)
                            )
                              ? erroredInputs.cvc
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <TextField
                          fullWidth
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Zip"
                          placeholder="Zip"
                          value={values.zip}
                          name="zip"
                          type="text"
                          margin="normal"
                          variant="outlined"
                          autoComplete="off"
                          error={Boolean(touched.zip && errors.zip)}
                          helperText={touched.zip && errors.zip}
                          onFocus={(e) => (e.target.placeholder = "")}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <div className="row">
                  <div className="col-md-12 mt-4">
                    <div className="d-flex justify-content-center">
                      {/* <Button type="submit">Verify</Button> */}
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
