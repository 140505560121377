import React, { useState } from "react";
import {
  Grid,
  Button,
  Tooltip,
  IconButton,
  InputAdornment,
} from "@mui/material";
import TextBoxField from "../QuestionTemplates/TextBoxField";
import RadioBox from "../QuestionTemplates/RadioBox";
import AutoCompleteBox from "../QuestionTemplates/AutoCompleteBox";
import DatePickerBox from "../QuestionTemplates/DatePickerBox";
import QuestionNotes from "../QuestionNotes";
import NameBox from "../QuestionTemplates/NameBox";
import DropBox from "../QuestionTemplates/DropBox";
import SelectBox from "../QuestionTemplates/SelectBox";
import PhoneInputBox from "../QuestionTemplates/PhoneInputBox";
import GoogleAddressBox from "../QuestionTemplates/GoogleAddressBox";
import CheckBoxField from "../QuestionTemplates/CheckBoxField";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import PercentIcon from "@mui/icons-material/Percent";
import HomeGoogleAddressBox from "../QuestionTemplates/HomeGoogleAddressBox";
import TextareaBoxField from "../QuestionTemplates/TextareaBoxField";
import TextMaskCustom from "../QuestionTemplates/TextMaskCustom";

export default function PostquestBox({
  question,
  onHandleSubmit,
  manualSubmit,
  setCheckPhoneNumber,
  setAddress,
  setFieldValue,
}) {
  const [isModal, setisModal] = useState(false);
  return (
    <Grid container flexDirection={"column"} justifyContent={"center"}>
      <Dialog
        open={isModal}
        onClose={() => setisModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">
          {"Note"}
          <IconButton
            aria-label="close"
            onClick={() => setisModal(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div
              dangerouslySetInnerHTML={{
                __html: question.question_description,
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setisModal(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Grid item md={12}>
        <h6 className="main-title">
          {question.section_name}
          <span style={{ float: "right" }}>
            {question.question_description
              .replace(/<p[^>]*>/g, "")
              .replace(/<\/p>/g, "") !== "" && (
              <HelpRoundedIcon
                style={{
                  color: "#fff",
                }}
                onClick={() => setisModal(true)}
              />
            )}
          </span>
        </h6>
      </Grid>
      <Grid item md={12}>
        <div
          dangerouslySetInnerHTML={{ __html: question.question }}
          className="postque-title"
          style={{ padding: 0 }}
        />

        {/* <h6 className="postque-title">{question.question}</h6> */}
      </Grid>
      <Grid item md={12}>
        {[1].includes(question.control) && (
          <TextBoxField
            name="answer"
            label={question.placeholder}
            style={{ width: "100%" }}
          />
        )}
        {question.control === 2 && (
          <RadioBox
            name="answer"
            options={question.options}
            label={question.placeholder}
            onSubmitForm={onHandleSubmit}
          />
        )}
        {question.control === 3 && (
          <CheckBoxField
            name="answer"
            options={question.options}
            label={question.placeholder}
          />
        )}
        {question.control === 4 &&
        [1, 5, 6].includes(question.control_handler) ? (
          <AutoCompleteBox
            name="answer"
            options={question.options}
            label={question.placeholder ? question.placeholder : "Enter an Address"}
            textFieldProps={{
              fullWidth: true,
              margin: "normal",
              variant: "standard",
            }}
          />
        ) : (
          question.control === 4 && (
            <SelectBox
              label={question.placeholder ? question.placeholder : "Select Option"}
              name="answer"
              answer={question.default_value}
              options={question.options}
            />
          )
        )}
        {question.control === 5 && (
          <TextareaBoxField
            label={question.placeholder}
            name="answer"
            answer={question.default_value}
          />
        )}
        {question.control === 6 && (
          <DropBox
            name="files"
            question={question}
            setFieldValue={setFieldValue}
          />
        )}
        {question.control === 8 && (
          <DatePickerBox name="answer" label={question.placeholder} />
        )}
        {question.control === 9 && (
          <TextBoxField
            name="answer"
            label={question.placeholder}
            type="email"
            style={{ width: "100%" }}
          />
        )}
        {question.control === 10 && (
          <TextBoxField
            name="answer"
            label={`Phone Number`}
            type="text"
            style={{ width: "100%" }}
            InputProps={{
              inputComponent: TextMaskCustom,
            }}
          />
          // <PhoneInputBox
          //   name="answer"
          //   label={question.placeholder}
          //   setCheckPhoneNumber={setCheckPhoneNumber}
          // />
        )}
        {question.question_id === 13 && question.control === 11 && (
          <TextBoxField
            name="answer"
            label={question.placeholder}
            type="number"
            style={{ width: "100%" }}
          />
        )}
        {question.question_id !== 13 && question.control === 11 && (
          <TextBoxField
            name="answer"
            label={question.placeholder}
            type="number"
            style={{ width: "100%" }}
          />
        )}
        {question.control === 12 && (
          <GoogleAddressBox
            name="answer"
            placeholder={question.placeholder?question.placeholder:"Enter an address"}
            address={question.address}
            setAddress={setAddress}
          />
        )}
        {question.control === 13 && (
          <DatePickerBox
            name="answer"
            label={question.placeholder}
            maxDate={new Date().setFullYear(new Date().getFullYear() - 18)}
          />
        )}

        {question.control === 14 && <NameBox />}

        {question.control === 15 && (
          <TextBoxField
            name="answer"
            label={question.placeholder}
            type="number"
            style={{ width: "100%" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PercentIcon />
                </InputAdornment>
              ),
            }}
          />
        )}

        {question.control === 16 && (
          <HomeGoogleAddressBox
            name="answer"
            placeholder={question.placeholder}
            address={question.address}
            setAddress={setAddress}
            unitTypes={question.unit_types}
            setFieldValue={setFieldValue}
          />
        )}

        {question.control === 17 && (
          <TextBoxField
            name="answer"
            label={question.placeholder}
            type="number"
            style={{ width: "100%" }}            
          />
        )}
      </Grid>
      {/* <Grid item>
        <QuestionNotes question_description={question.question_description} />
      </Grid> */}
      <Grid item style={{ paddingTop: 20 }}>
        <Grid container justifyContent="space-evenly" alignContent="center">
          <Button
            variant="contained"
            color="custom"
            onClick={() => manualSubmit()}
            disabled={question.is_previous === 1 ? true : false}
            className="btn-color"
          >
            Previous
          </Button>
          {question.is_skip === 1 && (
            <Button
              variant="contained"
              color="custom"
              onClick={() => manualSubmit({}, {}, true, true)}
              className="btn-color"
            >
              Skip
            </Button>
          )}
          <Button
            variant="contained"
            color="custom"
            onClick={() => onHandleSubmit()}
            className="btn-color"
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
