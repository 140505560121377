import { Grid } from "@material-ui/core";
import React from "react";

export default function CenterLoader({ minheight }) {
  return (
    <Grid
      style={{
        display: "flex",
        justifyContent: "center",
        minHeight: minheight ? minheight : "250px",
      }}
    >
      {/* <div className="spinner-grow" role="status"></div> */}
      <img src="/img/loader.gif" style={{ height: 100, alignSelf: "center" }} />
    </Grid>
  );
}
