import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import RegisterForm from "./Components/RegisterForm";
import { useNavigate } from "react-router";
import { Http } from "src/Services/Http";
import Page from "src/Components/Page";
import { login } from "src/redux/actions/sessionActions";

export const RegisterView = ({ login }) => {
  let navigate = useNavigate();
  const [responseMessage, setResponseMessage] = useState("");

  const handleRegister = async (values, actions) => {
    let rand_token = await localStorage.getItem("rand_token");
    await Http("POST", "guest/front_user", {
      ...values,
      device_id: rand_token,
    }).then(async (response) => {
      const { status, data } = response;
      if (status === 200) {
        localStorage.removeItem("first_name");
        localStorage.removeItem("last_name");
        localStorage.removeItem("email");
        localStorage.removeItem("mobile");
        setResponseMessage(response.message);
        actions.setErrors("");
        actions.resetForm();
        // if (data.token !== undefined) {
        //   // await localStorage.setItem("rand_token", data.replaced_id);
        //   // login(data.token, data.token_type, data);
        //   // navigate("/app/check-verified/register");
        //   setResponseError("");
        //   setResponseMessage(response.message);
        // } else {
        // }
      } else {
        if (status === 400) {
          actions.setErrors(data);
        }
      }
    });
  };
  useEffect(() => {
    const checkActivity = async () => {
      let rand_token = await localStorage.getItem("rand_token");
      let stage = await localStorage.getItem("stage");
      if (rand_token === null || stage === null || parseInt(stage) === 0) {
        navigate("/v1");
      }
      // if (parseInt(stage) === 2) {
      //   navigate("/package");
      // }
    };
    checkActivity();
  }, [navigate]);
  return (
    <Page title="Fernwood Cemetery Register">
      <div className="que-section">
        <div className="row">
          <div className="col-md-2 my-auto"></div>
          <div className="col-md-8 my-auto mx-auto">
            <RegisterForm
              handleRegister={handleRegister}
              responseMessage={responseMessage}
            />
          </div>
          <div className="col-md-2 my-auto"></div>
        </div>
      </div>
    </Page>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  login,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterView);
