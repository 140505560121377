import React from "react";
import { useNavigate } from "react-router";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Grid } from "@mui/material";

export const ThankYouView = (props) => {
  const navigate = useNavigate();
  const startQuestionnary = () => {
    navigate("/app/postquest");
  };

  return (
    <Grid
      style={{
        display: "flex",
        justifyContent: "center",
        minHeight: 500,
        backgroundImage: `url("/img/Rectangle24.png")`,
        backgroundSize: "cover",
        height: "95vh",
        backgroundPosition: "center",
        position: "relative",
        zIndex: "0",
      }}
      className="homesection_main"
    >
      <Container>
        <Row>
          <Col>
            <div className="thanks-section">
              <h2>Thank You For Choosing Fernwood Cemetery</h2>
              <div className="thank-box">
                <p className="">
                  Your payment has been successfully processed.
                </p>
                <Button
                  className="btn-apply mx-0 mt-3 mb-3"
                  onClick={() => {
                    startQuestionnary();
                  }}
                >
                  Start Questionnaire
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Grid>
  );
};

export default ThankYouView;
