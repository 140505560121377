import { Grid } from "@mui/material";
import React from "react";

export default function CenterLoader({ minHeight }) {
  return (
    <Grid
      style={{
        display: "flex",
        justifyContent: "center",
        minHeight: minHeight,
      }}
    >
      <img
        src="/img/logo/loading.gif"
        style={{ height: 100, alignSelf: "center" }}
      />
    </Grid>
  );
}
