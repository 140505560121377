import { Grid } from "@material-ui/core";
import React from "react";

export default function PaymentLoader({ minheight }) {
  return (
    <div>
      {/* <div
        className="spinner-grow"
        role="status"
        style={{
          position: "absolute",
          left: "50%",
          top: "45%",
          flexDirection: "row",
        }}
      ></div> */}
      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: minheight ? minheight : "250px",
          top: "45%",
        }}
      >
        <img
          src="/img/loader.gif"
          style={{ height: 100, alignSelf: "center" }}
        />
      </Grid>
      <div
        style={{
          position: "absolute",
          left: "40%",
          top: "35%",
          color: "#fff",
        }}
      >
        Your payment is currently being processed. Please wait...
      </div>
    </div>
  );
}
