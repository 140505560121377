import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
//import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CategorySelection from "../CategorySelection/index";
import { Http } from "src/Services/Http";
import { useDispatch } from "react-redux";
import { setPackageSelected } from "src/redux/actions";
import CustomStepper from "src/Components/Stepper";
import { Grid } from "@mui/material";

export default function PackageBody({
  data,
  crtPrice,
  QuestionConditionChk,
  PreneedDoneChk,
  ...otherProps
}) {
  const { name, price, description, addonCategory } = data;
  const navigate = useNavigate();
  const [isPaymentLoading, setisPaymentLoading] = useState(false);
  const [errorMessageForUrn, setErrorMessageForUrn] = useState("");
  const [errorMessageForAdditionServices, setErrorMessageForAdditionServices] =
    useState("");
  const [errorMessageForDelivery, setErrorMessageForDelivery] = useState("");
  const [errorMessageForDeliveryAddress, setErrorMessageForDeliveryAddress] =
    useState("");
  const dispatch = useDispatch();
  const savePackages = async () => {
    setisPaymentLoading(true);
    let addOns = [];
    //let addOnsData = [];

    let isErrro = false;
    let selectedCategories = [];

    let deliveryAddress = "";
    setErrorMessageForUrn("");
    setErrorMessageForAdditionServices("");
    setErrorMessageForDelivery("");
    setErrorMessageForDeliveryAddress("");
    otherProps.selected.map((item, index) => {
      if (!selectedCategories.includes(item.catId)) {
        selectedCategories.push(item.catId);
      }

      if(item.address !== undefined && item.address !== ''){
        deliveryAddress = item.address;
      }
      
      return addOns.push({
        id: item.id,
        quantity: item.quantity ? item.quantity : 0,
        state: item.state !== undefined ? item.state : "",
        address: item.address !== undefined ? item.address : "",
      });
    });

    console.log(deliveryAddress,'deliveryAddress');

    const caterror = document.getElementById("caterror");
    if (!selectedCategories.includes(1) && caterror) {
      isErrro = true;
      caterror.scrollIntoView({ behavior: "smooth" });
      setErrorMessageForUrn("Please select urn(s)");
    } else if (!selectedCategories.includes(28) && caterror) {
      isErrro = true;
      caterror.scrollIntoView({ behavior: "smooth" });
      setErrorMessageForAdditionServices("Please select Additional Service.");
    } else if (!selectedCategories.includes(2) && caterror) {
      isErrro = true;
      caterror.scrollIntoView({ behavior: "smooth" });
      setErrorMessageForDelivery("Please select your shipping option");
    } else if (
      selectedCategories.includes(2) &&
      deliveryAddress === "" &&
      caterror
    ) {
      isErrro = true;
      caterror.scrollIntoView({ behavior: "smooth" });
      setErrorMessageForDeliveryAddress("Please enter delivery address.");
    }

    if (!isErrro) {
      const package_id = JSON.parse(localStorage.getItem("packageData")).id;

      let local_discount = localStorage.getItem("discount");

      let discount_id = local_discount
        ? JSON.parse(local_discount).discount_id
        : "";

      let body = {
        package_id: package_id,
        addons_ids: addOns,
        // discount_id: discount_id,
        user_id: JSON.parse(localStorage.getItem("state")).session.user.id,
        no_of_cert: otherProps.certificate,
      };
      await Http("POST", "add_subscription_details", body).then((response) => {
        if (response.status === 200) {
          dispatch(setPackageSelected(true));
          QuestionConditionChk && !PreneedDoneChk
            ? navigate("/app/gwquest")
            : navigate("/app/payment");
        } else {
          alert(response.message);
        }
      });
    }
    setisPaymentLoading(false);
  };

  return (
    <div
      className="scrollable-section"
      style={{
        backgroundImage: `url("/img/Rectangle24.png")`,
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        position: "relative",
        zIndex: "0",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <CustomStepper currentStep={1} />
          </div>
        </div>
        <div className="row package-main">
          <div className="col-lg-9 col-md-12 mb-4">
            <div className="payment-op1">
              <h3>{`${name}`}</h3>
              <h6 className="text-white">
                ${`${parseFloat(price).toFixed(2)}`}
              </h6>
            </div>
            <ul className="que-list ">
              <li className="text-white">
                <i className="fa fa-arrow-circle-o-right" aria-hidden="true">
                  <FontAwesomeIcon icon={faArrowAltCircleRight} />
                </i>
                {`${description}`}
              </li>
            </ul>
            {addonCategory.map((item, index) => {
              if (item.addOns.length > 0) {
                let selectedData = otherProps.selected.filter(
                  ({ catId }) => catId === item.id
                );
                return (
                  <CategorySelection
                    key={index}
                    data={item}
                    index={index}
                    selected={selectedData}
                    getShippingTotal={otherProps.getShippingTotal}
                    errorMessageForUrn={errorMessageForUrn}
                    setErrorMessageForUrn={setErrorMessageForUrn}
                    errorMessageForAdditionServices={
                      errorMessageForAdditionServices
                    }
                    setErrorMessageForAdditionServices={
                      setErrorMessageForAdditionServices
                    }
                    errorMessageForDelivery={errorMessageForDelivery}
                    setErrorMessageForDelivery={setErrorMessageForDelivery}
                    errorMessageForDeliveryAddress={
                      errorMessageForDeliveryAddress
                    }
                    setErrorMessageForDeliveryAddress={
                      setErrorMessageForDeliveryAddress
                    }
                  />
                );
              }
            })}

            <div className="payment-op1">
              <h3>Select no. of death certificates</h3>
              <div className="que-option">
                <div className="number-input">
                  <button
                    onClick={() => {
                      if (otherProps.certificate > 1) {
                        otherProps.setCertificate(otherProps.certificate - 1);
                      }
                    }}
                    className="minus"
                  >
                    -
                  </button>
                  <input
                    className="quantity"
                    min="0"
                    name="quantity"
                    value={otherProps.certificate}
                    type="number"
                    // onChange={(e) => otherProps.setCertificate(e.target.value)}
                    readOnly={true}
                  />
                  <button
                    onClick={() =>
                      otherProps.setCertificate(
                        parseInt(otherProps.certificate) + 1
                      )
                    }
                    className="plus"
                  >
                    +
                  </button>
                </div>
              </div>
              <h6 className="text-white">
                $
                {parseFloat(
                  otherProps.certificate * crtPrice - crtPrice
                ).toFixed(2)}
              </h6>
            </div>
            <div className="payment-op1">
              <h3>Tax Calculation</h3>
              <h6 className="text-white">
                ${`${parseFloat(otherProps.getTotalTax).toFixed(2)}`}
              </h6>
            </div>
            <div className="payment-op1">
              <h3>
                <b>Total amount due</b>
              </h3>
              <h6 className="text-white">
                <b>${`${parseFloat(otherProps.total).toFixed(2)}`}</b>
              </h6>
            </div>

            {/* <div className="amount-note">
              <p>There are no additional costs or hidden charges.</p>
            </div> */}

            <div className="cat-error" id="caterror">
              {errorMessageForUrn !== "" && <h3>{errorMessageForUrn}</h3>}
              {errorMessageForAdditionServices !== "" && (
                <h3>{errorMessageForAdditionServices}</h3>
              )}
              {errorMessageForDelivery !== "" && (
                <h3>{errorMessageForDelivery}</h3>
              )}
              {errorMessageForDeliveryAddress !== "" && (
                <h3>{errorMessageForDeliveryAddress}</h3>
              )}
            </div>
            <button
              type="text"
              className="btn-apply mt-4 mx-0 mb-4"
              onClick={() => savePackages()}
            >
              Continue
            </button>
          </div>
          <div className="col-lg-3 col-md-12 pb-60">
            <div className="quote">
              <h6 className="text-white">Your Quote</h6>
              <p className="text-white">
                ${`${parseFloat(otherProps.total).toFixed(2)}`}
              </p>
              <button className="btn-apply" onClick={() => savePackages()}>
                Continue &nbsp;
                <i className="fa fa-arrow-right" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </Grid>
  );
}
