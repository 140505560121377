import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import CenterLoader from "src/Components/Loader/CenterLoader";
import { Http } from "src/Services/Http";
import OrderSummery from "./Components/OrderSummery";
import PaymentForm from "./Components/PaymentForm";
import PaymentLoader from "./Components/PaymentLoader";
import { setPaymentChk } from "src/redux/actions/sessionActions";
import CustomStepper from "src/Components/Stepper";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import * as Helper from "src/Services/Helper";
import {
  setCertificatePrice,
  setPackageData,
  emptyPackageData,
} from "src/redux/actions";
import { DialogContentText } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";

export class PaymentView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      redirect: null,
      isPaymentLoading: false,
      isValid: false,
      isAgree: false,
      isTermModal: false,
      isPrivacyModal: false,
    };
  }
  setValid = (arg) => {
    this.setState({ isValid: arg });
  };
  handleChangeChk = (arg) => {
    this.setState({ isAgree: arg.target.checked });
  };

  makePayment = async () => {
    this.setState({ isPaymentLoading: true });
    const PaymentInfo = JSON.parse(localStorage.getItem("PaymentData"));
    let addOns = [];
    this.props.categoryData.map(
      (
        item,
        index //(addOns[index] = item.id)
      ) => {
        return addOns.push({
          id: item.id,
          quantity: item.quantity ? item.quantity : 0,
          state: item.state !== undefined ? item.state : "",
          address: item.address !== undefined ? item.address : "",
        });
      }
    );
    const package_id = JSON.parse(localStorage.getItem("packageData")).id;
    const discount_id = JSON.parse(
      localStorage.getItem("discount")
    ).discount_id;
    let body = {
      card_number: parseInt(PaymentInfo.cardNumber.replace(/ /g, ""), 10),
      card_name: PaymentInfo.holderName,
      card_expiry: PaymentInfo.expiryDate.replace(/ /g, ""),
      card_cvv: parseInt(PaymentInfo.cvc),
      package_id: package_id,
      addons_ids: addOns,
      discount_id: discount_id,
      user_id: this.props.user_id,
      no_of_cert: JSON.parse(localStorage.getItem("certificate")),
    };

    await Http("POST", "payment", body).then(async (response) => {
      if (response.status === 200) {
        await this.props.setPaymentChk(true);
        this.setState({ redirect: "/app/thank-you" });
      } else {
        alert(response.message);
      }
    });
    this.setState({ isPaymentLoading: false });
  };

  setCertificate = async (arg) => {
    Helper.setItem("certificate", arg);
  };

  componentDidMount = async () => {
    const { setCertificate } = this;
    const {
      setCertificatePrice,
      setPackageData,
      emptyPackageData,
      isTermModal,
    } = this.props;

    emptyPackageData();

    const { data, status, message } = await Http("GET", "packages-services");
    if (status === 200) {
      Helper.setItem(
        "packageData",
        JSON.stringify({
          id: data.id,
          name: data.name,
          price: data.price,
          tax: data.tax,
        })
      );
      setCertificatePrice(parseInt(data.certificate_rate));
      // certificateCount = data.certificate_count;

      data.addonCategory.map((item, index) => {
        item.addOns.map((additem, addindex) => {
          if (additem.is_selected) {
            setPackageData({
              id: additem.id,
              catId: item.id,
              name: additem.service_name,
              price: additem.price,
              tax: additem.tax,
              type: item.selection_type,
              quantity: additem.quantity ? additem.quantity : 0,
              selected: additem.quantity ? true : false,
              included: additem.is_included ? true : false,
              state: additem.state ? additem.state : "",
              address: additem.address ? additem.address : "",
            });
          }
        });
      });
    } else {
      alert("API ERO+ROR" + message);
    }
    this.setState({ isLoading: false });
  };

  render() {
    const {
      isLoading,
      redirect,
      isPaymentLoading,
      isValid,
      isAgree,
      isTermModal,
      isPrivacyModal,
      return_by_question,
    } = this.state;
    // console.log(isValid + " = " + isAgree);
    if (redirect) {
      return <Navigate to={redirect} />;
    } else {
      if (isLoading) {
        return (
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              minHeight: 500,
              backgroundImage: `url("/img/Rectangle24.png")`,
              backgroundSize: "cover",
              height: "95vh",
              backgroundPosition: "center",
              position: "relative",
              zIndex: "0",
            }}
            className="paymentmain_section"
          >
            <CenterLoader />
          </Grid>
        );
      }
      if (isPaymentLoading) {
        return (
          <Grid
            className="paymentmain_section"
            style={{
              display: "flex",
              justifyContent: "center",
              minHeight: 500,
              backgroundImage: `url("/img/Rectangle24.png")`,
              backgroundSize: "cover",
              height: "95vh",
              backgroundPosition: "center",
              position: "relative",
              zIndex: "0",
            }}
          >
            <PaymentLoader />
          </Grid>
        );
      }

      return (
        <Grid
          // container
          // direction="row"
          style={{
            backgroundImage: `url("/img/Rectangle24.png")`,
            backgroundSize: "cover",
            position: "relative",
            zIndex: "0",
          }}
          className="paymentmain_section"
        >
          <div className="payment-section mt-5 pt-5">
            <div className="container">
              <CustomStepper currentStep={3} />
              <h2 className="pay-main-tital">
                Please enter your payment information below:
              </h2>

              <div className="row">
                <PaymentForm setValid={this.setValid} />
                <OrderSummery
                  crtPrice={this.props.crtPrice}
                  categoryData={this.props.categoryData}
                />
                <div className="col-md-12">
                  <div className="text-center purchase-section">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultChecked={isAgree}
                        onChange={this.handleChangeChk}
                        id="defaultCheck1"
                      />
                      <label
                        className="form-check-label"
                        // htmlFor="defaultCheck1"
                      >
                        I agree to Fernwood Cemetery{" "}
                        <span
                          className="gold"
                          style={{ cursor: "pointer" }}
                          // onClick={() => this.setState({ isTermModal: true })}
                        >
                          {" "}
                          <Link
                            className="gold paymentlink"
                            target="_blank"
                            // to={"//hollywoodforever.com/terms-and-conditions/"}
                            to={"https://www.fernwood.com/terms-of-service"}
                          >
                            Terms of Service
                          </Link>{" "}
                        </span>{" "}
                        and{" "}
                        <span
                          className="gold"
                          style={{ cursor: "pointer" }}
                          // onClick={() =>
                          //   this.setState({ isPrivacyModal: true })
                          // }
                        >
                          <Link
                            className="gold paymentlink"
                            target="_blank"
                            // to={"//hollywoodforever.com/privacy-policy/"}
                            to={"https://www.fernwood.com/privacy-policy"}
                          >
                            Privacy Policy.
                          </Link>{" "}
                        </span>
                      </label>
                    </div>
                    <div className="form-check">
                      {isValid && isAgree && (
                        <button
                          type="button"
                          className="btn-purchase"
                          onClick={() => this.makePayment()}
                        >
                          Complete Purchase
                        </button>
                      )}
                      {isValid === false || isAgree === false ? (
                        <button
                          type="button"
                          className="btn-purchase"
                          onClick={() => console.log("missing info")}
                          disabled={true}
                        >
                          Complete Purchase
                        </button>
                      ) : null}
                    </div>
                    <div className="form-check">
                      <Link
                        to={
                          return_by_question ? "/app/packages" : "/app/gwquest"
                        }
                        className="btn-back"
                      >
                        Return
                      </Link>
                    </div>
                  </div>
                </div>
                <Dialog
                  open={isTermModal}
                  onClose={() => this.setState({ isTermModal: false })}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  maxWidth="sm"
                  fullWidth={true}
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Terms of Service"}
                    <IconButton
                      aria-label="close"
                      onClick={() => this.setState({ isTermModal: false })}
                      style={{
                        position: "absolute",
                        right: 8,
                        top: 10,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent dividers>
                    <DialogContentText id="alert-dialog-description">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                      <p>
                        It has survived not only five centuries, but also the
                        leap into electronic typesetting, remaining essentially
                        unchanged. It was popularised in the 1960s with the
                        release of Letraset sheets containing Lorem Ipsum
                        passages, and more recently with desktop publishing
                        software like Aldus PageMaker including versions of
                        Lorem Ipsum.
                      </p>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => this.setState({ isTermModal: false })}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={isPrivacyModal}
                  onClose={() => this.setState({ isPrivacyModal: false })}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  maxWidth="sm"
                  fullWidth={true}
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Privacy Policy"}
                    <IconButton
                      aria-label="close"
                      onClick={() => this.setState({ isPrivacyModal: false })}
                      style={{
                        position: "absolute",
                        right: 8,
                        top: 10,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent dividers>
                    <DialogContentText id="alert-dialog-description">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                      <p>
                        It has survived not only five centuries, but also the
                        leap into electronic typesetting, remaining essentially
                        unchanged. It was popularised in the 1960s with the
                        release of Letraset sheets containing Lorem Ipsum
                        passages, and more recently with desktop publishing
                        software like Aldus PageMaker including versions of
                        Lorem Ipsum.
                      </p>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => this.setState({ isPrivacyModal: false })}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          </div>
        </Grid>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  user_id: state.session.user.id,
  crtPrice: state.package.certificatePrice,
  categoryData: state.package.categoryData,
  return_by_question: state.session.preeneeddonechk,
});

const mapDispatchToProps = {
  setCertificatePrice,
  setPackageData,
  emptyPackageData,
  setPaymentChk,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentView);
