import React, { useState } from "react";
import { connect } from "react-redux";
import { setPackageData } from "src/redux/actions";
import placeholder from "src/Assets/img/image_placeholder.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from "@mui/icons-material/Close";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Box, IconButton, Modal } from "@mui/material";

export const RadioOption = ({
  data,
  categoryKey,
  addOnsKey,
  selected,
  type,
  setDeliveryAddon,
  ...props
}) => {
  let isSelected = false;
  let selectedString = type ? "checkbox" : "radio";
  const {
    id,
    service_name,
    price,
    tax,
    image,
    thumb_image,
    is_included,
    is_selected,
    quantity,
  } = data;
  if (selected) {
    if (is_selected) {
      isSelected = is_selected;
      selectedString = type ? "checkbox-checked" : "radio-checked";
    } else if (selected.id === id) {
      isSelected = true;
      selectedString = type ? "checkbox-checked" : "radio-checked";
    }
  }
  const [open, setOpen] = useState(false);
  const [Mainimage, setImage] = useState("false");

  const handleClose = () => {
    setOpen(false);
  };

  const handleImage = (value) => {
    setImage(value);
    setOpen(true);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  return (
    <>
      <div className="plans">
        <label className="plan basic-plan" htmlFor={"flexRadioDefault" + id}>
          <input
            type={type ? "checkbox" : "radio"}
            // className="addon_box"
            name={"addons_ids[" + id + "]"}
            id={"flexRadioDefault" + id}
            checked={isSelected}
            value={id}
            onChange={(e) => {
              let addon = props.categoryData.find((item) => item.id === id);
              let categoryWiseSelectedAddonsCount = 0;
              let categoryWiseSelectedAddons = props.categoryData.filter(
                (item) => item.catId === categoryKey
              );
              if (categoryKey === 1) {
                props.setErrorMessageForUrn("");
              }
              if (categoryKey === 28) {
                props.setErrorMessageForAdditionServices("");
              }
              if (categoryKey === 2) {
                props.setErrorMessageForDelivery("");
                props.setErrorMessageForDeliveryAddress("");
              }
              if (categoryWiseSelectedAddons !== undefined) {
                if (categoryWiseSelectedAddons.length > 0) {
                  categoryWiseSelectedAddons.map((catSelItem) => {
                    categoryWiseSelectedAddonsCount += catSelItem.quantity;
                  });
                }
              }
              if (isSelected) {
                addon = {
                  ...addon,
                  selected: false,
                  quantity: 0,
                };
                props.setPackageData(addon);
              } else {
                if (type) {
                  if (categoryWiseSelectedAddonsCount < 10) {
                    props.setPackageData({
                      id: id,
                      catId: categoryKey,
                      name: service_name,
                      price: price,
                      tax: tax,
                      type: type,
                      selected: true,
                      included: is_included ? true : false,
                      quantity: 1,
                      address: "",
                    });
                  }
                } else {
                  props.setPackageData({
                    id: id,
                    catId: categoryKey,
                    name: service_name,
                    price: price,
                    tax: tax,
                    type: type,
                    selected: true,
                    quantity: 1,
                    address: id === 9 ? props.collectPerson : "",
                  });
                }
              }
            }}
          />
          <div className="plan-content">
            {thumb_image !== "" ? (
              <img
                loading="lazy"
                className="mx-3 lgt-img text-white"
                src={`${thumb_image}`}
                alt=""
                onClick={(e) => handleImage(image)}
              />
            ) : (
              <img
                loading="lazy"
                className="lgt-img text-white"
                src={placeholder}
                alt=""
                onClick={(e) => handleImage(placeholder)}
              />
            )}
            <div className="plan-details">
              <span className="text-white">{`${service_name}`}</span>

              {type ? (
                is_included ? (
                  <p className="service-price text-white">
                    ${parseFloat(price).toFixed(2)} (1 Included)
                  </p>
                ) : (
                  <p className="service-price text-white">
                    +${parseFloat(price).toFixed(2)}
                  </p>
                )
              ) : (
                ""
              )}
            </div>
            {isSelected ? (
              <div className="planselectedicon">
                <i className="fa fa-arrow-circle-o-right" aria-hidden="true">
                  <FontAwesomeIcon icon={faCheckCircle} />
                </i>
              </div>
            ) : (
              ""
            )}
          </div>
        </label>
      </div>
      {type ? (
        <div className="number-input category-pkg-qty">
          <button
            // onClick={() => props.setPackageQty(id, parseInt(props.pkgqty) + 1)}
            onClick={() => {
              let addon = props.categoryData.find((item) => item.id === id);
              let categoryWiseSelectedAddonsCount = 0;
              let categoryWiseSelectedAddons = props.categoryData.filter(
                (item) => item.catId === categoryKey
              );

              if (categoryWiseSelectedAddons !== undefined) {
                if (categoryWiseSelectedAddons.length > 0) {
                  categoryWiseSelectedAddons.map((catSelItem) => {
                    categoryWiseSelectedAddonsCount += catSelItem.quantity;
                  });
                }
              }

              if (addon !== undefined) {
                if (addon.quantity === undefined) {
                  addon = {
                    ...addon,
                    selected: true,
                    quantity: 1,
                  };
                } else {
                  if (addon.quantity < 10) {
                    addon = {
                      ...addon,
                      quantity: addon.quantity + 1,
                    };
                  }
                }
                if (categoryWiseSelectedAddonsCount < 10) {
                  props.setPackageData(addon);
                }
              } else {
                if (categoryWiseSelectedAddonsCount < 10) {
                  props.setPackageData({
                    id: id,
                    catId: categoryKey,
                    name: service_name,
                    price: price,
                    tax: tax,
                    type: type,
                    selected: true,
                    included: is_included ? true : false,
                    quantity: 1,
                    address: props.collectPerson,
                  });
                }
              }
            }}
            className="plus"
          >
            +
          </button>
          <input
            className="quantity"
            min="0"
            name="quantity"
            value={
              selected !== undefined
                ? selected.quantity
                  ? selected.quantity
                  : quantity
                : 0
            }
            type="number"
            max="10"
            readOnly={true}
          />
          <button
            onClick={() => {
              let addon = props.categoryData.find((item) => item.id === id);
              if (
                selected !== undefined &&
                selected.quantity !== undefined &&
                selected.quantity >= 1 &&
                selected.quantity <= 10
              ) {
                if (addon !== undefined) {
                  if (addon.quantity !== undefined) {
                    if (selected.quantity === 1) {
                      addon = {
                        ...addon,
                        selected: false,
                        quantity: addon.quantity - 1,
                      };
                    } else {
                      addon = {
                        ...addon,
                        quantity: addon.quantity - 1,
                      };
                    }
                  }
                  props.setPackageData(addon);
                }
              }
            }}
            className="minus"
          >
            -
          </button>
        </div>
      ) : (
        ""
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={() => handleClose(false)}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={Mainimage}
            alt="asd"
            style={{ maxHeight: "90%", maxWidth: "90%" }}
          />
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  categoryData: state.package.categoryData,
});

const mapDispatchToProps = {
  setPackageData,
};

export default connect(mapStateToProps, mapDispatchToProps)(RadioOption);
