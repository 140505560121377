import React from "react";
import {
  Container,
  Button,
  Card
} from "react-bootstrap";
import { connect } from "react-redux";
import * as Messages from "src/constants/Messages";

export const ConfirmBox = ({ data, answer, setAnswer, confirmerror, setConfirmError, sectiondata, ...props }) => {
  let classString = "text-left btn col-12 mb-1 btn-custom";
  if (1 === answer) {
    classString = classString + " active";
  }

  return (
    <div>
      <Container fluid="md">
        <Card className="mb-2" style={{ width: "100%" }} >
          <Card.Header className="reviewsection_title">
            Confirm
          </Card.Header>
          <Card.Body className="list-group-flush">
            <Card.Text style={{ color: "#000" }}>
              By Confirming below, you will create Legal documents that will be sent via email for signature.
            </Card.Text>
            <div className="box">



              <div
                className={classString}
                onClick={() => setAnswer(1)}
              >
                I acknowledge that this questionnaire will be used to generate legal official documents so it's imperative that the questionnaire is answered correctly.
              </div>
            </div>
            {confirmerror !== "" && (
              <div className="invalid-feedback d-block">{confirmerror}</div>
            )}
          </Card.Body>
          <Card.Footer>
            <Button
              variant="primary"
              onClick={() => {
                props.getReview();
                props.setSectionData(
                  [...sectiondata],
                  [...sectiondata][sectiondata.length - 2].is_active = true,
                );
              }}
            >
              Back
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                if (answer === "") {
                  setConfirmError(Messages.FIELD_REQUIRED);
                } else {
                  props.getConfirmation();
                }
              }}
            >
              Confirm
            </Button>
          </Card.Footer>
        </Card>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmBox);
