import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import * as Messages from "src/constants/Messages";
//import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import CenterLoader from "src/Components/Loader/CenterLoader";
import TextInput from "src/Components/Formik/TextInput";
import { Grid, Button, Paper } from "@material-ui/core";
import TextMaskCustom from "src/Components/TextMaskCustom";
import { FormControl, FormHelperText } from "@mui/material";

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .matches(/^[A-Za-z ]*$/, Messages.ONLY_ALPHAINFIRSTNAME)
    .required(Messages.FIELD_REQUIRED),
  last_name: Yup.string()
    .matches(/^[A-Za-z ]*$/, Messages.ONLY_ALPHAINLASTNAME)
    .required(Messages.FIELD_REQUIRED),
  email: Yup.string()
    .email(Messages.VALID_EMAIL)
    .max(100, Messages.MAX_Email)
    .required(Messages.FIELD_REQUIRED),
  mobile: Yup.string().required(Messages.FIELD_REQUIRED).min(10, ""),
});

export default function RegisterForm({
  handleRegister,
  responseMessage
}) {
  const [checkPhoneNumber, setCheckPhoneNumber] = useState(0);

  const initialValues = {
    first_name: localStorage.getItem("first_name")
      ? localStorage.getItem("first_name")
      : "",
    last_name: localStorage.getItem("last_name")
      ? localStorage.getItem("last_name")
      : "",
    email: localStorage.getItem("email") ? localStorage.getItem("email") : "",
    mobile: localStorage.getItem("mobile")
      ? localStorage.getItem("mobile")
      : "",
  };

  return (
    <Paper elevation={3} style={{ padding: 25, minHeight: 280 }}>
      <Grid container direction="column">
        <Grid item>
          <h6 className="que-title">Register</h6>
          <br />
          <p style={{ color: "#000", textAlign: "center" }}>
            Please register below to receive your no obligation complimentary
            quote.
          </p>
          <hr />
        </Grid>
        <Grid item style={{ padding: 15 }} className="Register_form">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
              if (checkPhoneNumber === 0) {
                await handleRegister(values,actions);
              }
            }}
          >
            {({
              handleSubmit,
              setFieldValue,
              isValid
            }) => {
              // if (isSubmitting) {
              //   return <CenterLoader />;
              // }
              return (
                <Form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <TextInput
                        name="first_name"
                        type="text"
                        placeholder="First Name"
                        onChange={(event) => {
                          setFieldValue("first_name", event.target.value);
                          localStorage.setItem(
                            "first_name",
                            event.target.value
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextInput
                        name="last_name"
                        type="text"
                        placeholder="Last Name"
                        onChange={(event) => {
                          setFieldValue("last_name", event.target.value);
                          localStorage.setItem("last_name", event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextInput
                        name="email"
                        type="email"
                        placeholder="Email"
                        onChange={(event) => {
                          setFieldValue("email", event.target.value);
                          localStorage.setItem("email", event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl variant="outlined" fullWidth>
                        <TextInput
                          name="mobile"
                          label="Phone Number"
                          onChange={(event) => {
                            setFieldValue("mobile", event.target.value);
                            localStorage.setItem("mobile", event.target.value);
                          }}
                          InputProps={{
                            inputComponent: TextMaskCustom,
                          }}
                        />
                      </FormControl>
                    </Grid>
                    {responseMessage !== undefined &&
                      responseMessage !== "" && (
                        <Grid item xs={12} md={12}>
                          <FormHelperText
                            className="Mui-error"
                            style={{ color: "green" }}
                          >
                            {responseMessage}
                          </FormHelperText>
                        </Grid>
                      )}
                    
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item style={{ paddingTop: 10 }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        component={Link}
                        to="/"
                        className="btn-color"
                      >
                        Go Back
                      </Button>
                      {/* <Link to="/v1">Go Back</Link> */}
                    </Grid>
                    <Grid item style={{ paddingTop: 10 }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        type="submit"
                        disabled={!isValid}
                        className="btn-color"
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </Paper>
  );
}
